import React from 'react';
import { STORE_URLS } from '../../../../utils/constants';
import RoundBtn from '../../../round-btn';
import SectionTitle from '../../../section-title';
import StoreBtn from '../../../store-btn';
import * as style from './index.module.scss';

export default function DownloadApp() {
  return (
    <section className={style.downloadApp + ' overflow-hidden position-relative m-auto'}>
      <div className="container h-100 text-center">
        <div className="row">
          <SectionTitle
            className={style.title + ' col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 '}
          >
            Download and <br />
            start saving.
          </SectionTitle>
        </div>
        {/* <div className="row my-4">
          <div
            className={
              style.inputWrapper +
              ' col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 ' +
              ' p-2 d-flex flex-column flex-sm-row align-items-stretch justify-content-sm-between'
            }
          >
            <input
              className={'p-3 my-3 my-sm-0'}
              type="text"
              placeholder="Enter your phone number..."
              autoComplete="no"
            />
            <RoundBtn className={style.primaryBtn + ' '}>Text me the app</RoundBtn>
          </div>
        </div> */}
        <div className="row my-4">
          <div
            className={
              style.storeButtons +
              'col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-center'
            }
          >
            <StoreBtn
              className={style.storeBtn + ' my-2 my-sm-4 mx-sm-2'}
              store={'apple'}
              darkMode={true}
              url={STORE_URLS.iosAppStore}
            />
            <StoreBtn
              className={style.storeBtn + ' my-2 my-sm-4 mx-sm-2'}
              store={'google'}
              darkMode={true}
              url={STORE_URLS.playStore}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
