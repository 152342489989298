import React, { PropsWithChildren } from 'react';
import { StylableProp } from '../../utils/stylable-prop';
import * as style from './index.module.scss';

import frame from '../../images/iphone_frame.svg';

export interface PhoneProps {
  screenshot: string;
}

export default function PhoneScreenshot(props: PropsWithChildren<StylableProp<PhoneProps>>) {
  return (
    <div className={`${style.container} ${props.className} d-grid`}>
      <img className={style.screenshot} src={props.screenshot} width="86%" height="auto" />
      <img className={''} src={frame} />
    </div>
  );
}
