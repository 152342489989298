import React from 'react';
import FeatureSection from '../../../feature-section';
import { FeatureSectionProps } from '../../../feature-section/model';
import { trackScoreArt } from '../../shared/section-items/track-score';

export default function TrackYourApp() {
  const data: FeatureSectionProps = {
    items: [
      {
        title: 'Track your app with ours.',
        subtitle: 'Stay Connected',
        description: (
          <>
            <span>
              Sure, getting approved is nice, but Nuborrow goes a step further with our mobile and
              web based tracking tools.
            </span>
            <br />
            <br />
            <span>
              Know where you are in the process at all times, check in on your credit score, and
              chat with us whenever you need to.
            </span>
          </>
        ),
        art: trackScoreArt('right'),
        actions: [{ label: `Get Started`, href: 'https://apply.nuborrow.com', isPrimary: true }]
      }
    ]
  };

  return <FeatureSection {...data} />;
}
