import React from 'react';
import FeatureSection from '../../../feature-section';
import { FeatureSectionProps } from '../../../feature-section/model';
import { trackScoreArt } from '../../shared/section-items/track-score';

export default function SaveMoney() {
  const data: FeatureSectionProps = {
    items: [
      {
        title: 'Tackle your honey-do list.',
        subtitle: 'HELOC',
        description: (
          <>
            Updating your kitchen, bathrooms, and the basement is proven to add the most value. Why
            not tackle your honey-do list this year and add a line of credit at a rate much lower
            than the average credit card.
          </>
        ),
        art: trackScoreArt('right'),
        actions: [{ label: `Yes, Unlock My Equity`, href: 'https://apply.nuborrow.com', isPrimary: true }]
      }
    ]
  };

  return <FeatureSection {...data} />;
}
