import React, { useState } from 'react';
import Layout from '../components/layout';
import RateBanner, { RateBannerProps } from '../components/rate-banner';
import RateComparison, {
  RateComparisonItem,
  RateComparisonProps
} from '../components/rate-comparison';
import TrackYourApp from '../components/sections/line/track-app';
import UseEquity from '../components/sections/line/use-equity';
import DownloadApp from '../components/sections/shared/download';
import SaveMoney from '../components/sections/shared/save-money';
import TrackingPixel from '../components/tracking-pixel';
import Nuborrow from '../images/nuborrow_logo.svg';
import { RootState } from '../utils/root-state';

export default function EquityLinePage() {
  const [rate, setRate] = useState(0);
  const [effectiveDate, setEffectiveDate] = useState(new Date(1970, 0));

  const rateProps: RateBannerProps = {
    title: 'Flex your equity muscles with a home line.',
    rate,
    description: (
      <>
        <span>Need access to cash, but love your current first mortgage?</span>
        <br className={'d-none d-lg-block'} />
        &nbsp;
        <span>
          Why not keep your current mortgage and unlock the equity in your home with a low-rate home
          equity line?
        </span>
      </>
    ),
    actionButtonItems: [
      {
        label: 'Get Started',
        href: 'https://products.nuborrow.com/#line_of_credit_basic_detail',
        isPrimary: true
      }
    ]
  };

  const [rateComparisonItems, setRateComparisonItems] = useState<RateComparisonItem[]>([
    {
      logo: Nuborrow,
      primary: {
        highlight: '4.95%',
        label: 'Prime Rate +'
      },
      secondary: {
        highlight: '60% LTV',
        label: 'Loan on Value'
      }
    },
    {
      logo: Nuborrow,
      primary: {
        highlight: '5.99%',
        label: 'Prime Rate +'
      },
      secondary: {
        highlight: '80% LTV',
        label: 'Loan on Value'
      }
    },
    {
      logo: Nuborrow,
      primary: {
        highlight: '9.89%',
        label: 'Prime Rate +'
      },
      secondary: {
        highlight: '85% LTV',
        label: 'Loan on Value'
      }
    }
  ]);

  const rateComparison: RateComparisonProps = {
    title: `Today's best rates on a Home Equity Line of Credit.`,
    items: rateComparisonItems,
    effectiveDate: effectiveDate
  };

  const handleState = (state: RootState) => {
    setRate(state.findLowestRateForProduct('Equity Line')?.rate ?? 0);

    if (!state.productRates['Equity Line']) {
      return;
    }

    const list = state.findLowestRatesForProduct('Equity Line', 3);
    const items: RateComparisonItem[] = list.map((bank, index) => ({
      logo: state.getLogoForBank(bank.name),
      primary: {
        highlight: `${bank.rate}%`,
        label: 'Prime Rate +'
      },
      secondary: {
        highlight: `${bank.ltv}% LTV`,
        label: 'Loan on Value'
      }
    }));

    setEffectiveDate(new Date(state.productRates['Equity Line'].effectiveDate));
    setRateComparisonItems(items);
  };

  return (
    <Layout pageTitle={'Home Equity Line'} onRootState={handleState}>
      <RateBanner {...rateProps} />
      <RateComparison {...rateComparison} />
      <SaveMoney />
      <UseEquity />
      <TrackYourApp />
      <DownloadApp />

      <TrackingPixel src="https://geo-tracker.ads.thinkmidbrain.com/hyperad/pixel-tracking?order=112933&action=landing" />
    </Layout>
  );
}
