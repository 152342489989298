import { motion, useMotionValue, useTransform } from 'framer-motion';
import React, { useEffect } from 'react';
import { useViewportIntersection } from '../../../../hooks/use-viewport-intersection';
import app_screenshot from '../../../../images/app_screenshot.svg';
import { FeatureSectionItem } from '../../../feature-section/model';
import PhoneScreenshot from '../../../phone-screenshot';
import * as style from './track-score.module.scss';

export const trackScoreArt = (
  direction: 'left' | 'right' = 'left',
  screenshot = app_screenshot
) => {
  const [ref, ratio] = useViewportIntersection<HTMLDivElement>();

  const scrollPos = useMotionValue(0);
  const translateX = useTransform(
    scrollPos,
    [-1, 0],
    [direction === 'left' ? '-100%' : '100%', '0%']
  );

  useEffect(() => scrollPos.set(ratio), [ratio]);

  return (
    <div ref={ref} className={style.trackWrapper + ' d-grid'}>
      <div
        className={
          style.circleWrapper + ' d-flex flex-column align-items-center justify-content-center'
        }
      >
        <motion.div className={style.circle} style={{ translateX }}></motion.div>
      </div>
      <PhoneScreenshot className={style.iphoneContainer} screenshot={screenshot} />
    </div>
  );
};

export const trackScoreItem: FeatureSectionItem = {
  align: 'right',
  title: 'Track your score.\nImprove your credit.\nGet lower rates.',
  subtitle: '',
  description: `See how your finances impact your score and learn how to make small changes to boost your credit profile with our MyMarble partnership.  Build stronger credit, qualify for lower interest rates, and earn rewards with Nuborrow.`,
  actions: [{ label: `Track your score`, href: 'https://apply.nuborrow.com', isPrimary: true }]
};
